.Visual {
  width: 100vw;
  overflow: hidden;
  margin-bottom: -50px ;
}
.VisualContainer {
  width: 95%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
}
.VisualBackground {
  position: relative;
  width: 100%;
  height: 200px;
  background-position: top;
  background-size: cover;
}

.VisualImage {
  display: flex;
  width: 90%;
  margin-left: 8%;
  height: auto;
  object-fit: fill
}

.VisualContent {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.VisualTitle {
  text-align: start;
  color: #000000;
  font-family: "Roboto Bold";
  font-size: 54px;
}
.Yellow {
  color: #f1b62a;
}
.VisualText {
  font-size: 16px;
  font-family: "Roboto";
}
.VisualButton {
  width: 250px;
  height: 50px;
  border-radius: 18px;
  margin-top: 16px;
  background-color: #f1b62a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VisualButton:hover{
  opacity: 0.8;
}
.VisualButtonText {
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 400;
  color: #fff !important;
  text-align: center;
}

@media (max-width: 1024px) {
  .Visual {
    margin-bottom: 60px ;
  }
  
.VisualContent {
  width: 90%;
  margin-top: -100px;
  z-index: 20;
}
  .VisualImage {
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .VisualContainer {
    width: 90%;
    z-index: 6;
    margin: 0 auto;
    display: flex;
    position: relative;
    flex-direction: column-reverse;
  }
  .VisualTitle {
    font-size: 32px;
    font-weight: 700;
  }
  .VisualText {
    text-align: start;
    color: #313131 !important;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
  }
  .VisualBackground {
    position: relative;
    width: 100%;
    height: 100px;
    background-position: top;
    background-size: cover;
  }
}
