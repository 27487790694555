.InputContainer {
  position: relative;
  width: 99%;
  display: flex;
  flex: 1;
  border-radius: 20px;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@include placeholder {
  color: #7e7e7e;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  /* Text style for "İsim" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.Input {
  width: 95%;
  height: 135px;
  font-family: Roboto;
  font-size: 18px;
  display: flex;
  flex: 1;
  border-radius: 10px;
  padding-left: 24px;
  padding-top: 20px;
  background-color: #f5f5f5;
  border: none;
  resize: none;

  &:focus {
    border: none;
  }
}
.Required {
  top: 6px;
  right: 23px;
  position: absolute;
  color: #f1b62a;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
  /* Text style for "*zorunlu" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
