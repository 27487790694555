body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
[contenteditable="true"]:focus {
  outline: none;
}
a{
  text-decoration: none;
}

input:checked ~ .checkmark {
  background-color: aliceblue !important;
}