.Visual {
  width: 100%;
  margin-bottom: 80px;
}
.VisualContainer {
  width: 65%;
  z-index: 6;
  margin: 0 5%;
  display: flex;
  position: relative;
  flex-direction: row-reverse;
}
.VisualBackground1 {
  position: relative;
  width: 100%;
  height: 200px;
  background-position: top;
  background-size: cover;
  background-color: #f5f5f5;
}
.VisualBackground {
  position: relative;
  width: 100%;
  height: 200px;
  background-position: top;
  background-size: cover;
}

.VisualImage {
  margin-top: 61px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex: 1;
}

.VisualContent {
  display: flex;
  flex-direction: column;
  flex: 2;
}
.VisualTitle {
  text-align: start;
  color: #000000;
    color: #f1b62a;
    font-size: 32px;
  font-family: "Roboto Bold";
}

.VisualText {
  text-align: start;
  color: #313131;
  font-size: 16px;
  width: 50%;
  margin: 32px 0px;
  font-family: "Roboto Bold";
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.VisualButton {
  width: 240px;
  cursor: pointer;
  border: none;
  height: 64px;
  border-radius: 10px;
  background-color: #f1b62a;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    /* Style for "Tamamla" */
    color: #fff;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
}
.Form {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 32px;
}
.Form1 {
  display: flex;
  flex-wrap: nowrap;
  width: 75%;
}

.textInfo {
  /* Style for "Gofody bu" */
  height: 24px;
  color: #7e7e7e;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin-left: 38px;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  flex: 2;
}
.checkbox {
  display: flex;
  width: 24px;
  height: 24px;
}
.checkboxText {
  display: flex;
  flex-direction: row;
  flex: 1;
  color: #313131;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 400;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-right: 20px;
  margin-left: 10px;
}
.FormFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 1024px) {
  
  .Form {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 0px;
  }
  .VisualText {
    width: 90%;
  }
  .FormFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .VisualContainer {
    width: 90%;
    z-index: 6;
    margin: 0 auto;
    display: flex;
    position: relative;
    flex-direction: row-reverse;
  }
  .VisualBackground1 {
    position: relative;
    width: 100%;
    height: 50px;
    background-position: top;
    background-size: cover;
    background-color: #f5f5f5;
  }
  .Form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  .Form1 {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
  }
  .VisualBackground {
    position: relative;
    width: 100%;
    height: 50px;
    background-position: top;
    background-size: cover;
  }
}
