.InputContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-basis: 49%;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@include placeholder {
  color: #7e7e7e;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  /* Text style for "İsim" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.Input {
  width: 99%;
  padding-top: 8px;
  height: 42px;
  font-family: Roboto;
  font-size: 16px;
  display: flex;
  flex: 1;
  border-radius: 10px;
  padding-left: 24px;
  background-color: #f5f5f5;
  border: none;
  margin-bottom: 12px;
  &:focus {
    border: none;
  }
}
.Label {
  top: 4px;
  left: 24px;
  position: absolute;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  /* Text style for "İl Seçiniz" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: "#d6d5d5";
}
.Required {
  top: 6px;
  right: 23px;
  position: absolute;
  width: 47px;
  height: 20px;
  color: #f1b62a;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
  /* Text style for "*zorunlu" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.textInfo {
  /* Style for "Gofody bu" */
  color: #7e7e7e;
  font-family: Roboto;
  font-size: 11px;
  width: 110%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  position: absolute;
  bottom: -10px;
  left: 16px;
}
@media (max-width: 1024px) {
  .InputContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-basis: 49%;
    margin-left: 0 !important;
  }
}
