.Header {
  height: 108px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.HeaderContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  padding: 0px 5%;
  margin: 0 auto;
}
.ButtonIcon {
margin-right: 8px;
}
.LogoWrapper {
  justify-content: flex-start;
}
.Logo {
  width: 120px;
  height: 68px;
  display: flex;
}
.LogoMobile {
  width: 120px;
  height: 68px;
  display: flex;
}

.LinkWrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
.LinkContainerActive{
  border: 2px solid #f1b62a;
  border-radius: 25px;
  margin: 0px 15px;
  padding: 2px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.LinkContainer{
  border: 2px solid white;
  border-radius: 25px;
  padding: 2px 20px;
  margin: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.LinkActive {
  cursor: pointer;
  display: flex;
  color: #f1b62a;
  font-family: "Roboto Bold";
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}
.Link {
  cursor: pointer;
  display: flex;
  color: #000000;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.Button {
  height: 24px;
  margin-right: 8px;
  padding: 4px 16px 4px 4px;
  cursor: pointer;
  border-radius: 100px;
  cursor: pointer;
  border: 2px solid #f1b62a;
  background-color: white;
  display: flex;
  align-items: center;
 min-width: 135px;
}

.Button:hover{
  opacity: 0.7;
}

.ButtonText {
  color: #f1b62a;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
  /* Text style for "Restoran B" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.HeaderMobile {
  display: none;
}
@media (max-width: 1024px) {
  .Header {
    display: none;
  }
  .Logo {
    width: 68px;
    height: 68px;
    display: flex;
  }
  .HeaderContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
  }
  .Link{
    width: 100%;
  }
  .Button{
    width: 100%;
  }
  .HeaderMobile {
    height: 128px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
