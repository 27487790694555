.Footer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.FooterContainer {
  margin: 0 5%;
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 16px;
}
.left {
  width: 48%;
  margin-left: 2%;
}
.kvkk {
  font-family: "Roboto";
  color: #000000;
  font-size: 16px !important;
  font-weight: 600;
  margin: 8px 0px;
}

.kvkk:hover {
  color: #000000a9;

}

.Form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.FooterImage {
  margin-top: 61px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 8px;
  flex: 1;
}
.FooterForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 100px;
}
.FooterContent {
  display: flex;
  flex-direction: column;
  flex: 1.5;
}
.FooterTitle {
  font-size: 60px;
  margin: 0 5%;
  font-weight: 700;
  color: #f1b62a;
  font-family: "Roboto";
}
.FooterText {
  text-align: start;
  color: #313131;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 400;
}
.footerPhone {
  text-align: start;
  color: #313131;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
.ButtonText {
  /* Style for "Restoran B" */
  cursor: pointer;
  margin-left: 35px;
  margin-bottom: 5px;
  color: #000000;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  align-items: center;
  margin-left: unset;
}
.FooterButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  border-radius: 100px;
  background-color: #f1b62a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
}
.formButtonText {
  color: #fff;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 600;
}
.FormButton {
  width: 250px;
  text-align: center;
  height: 48px;
  border-radius: 10px;
  background-color: #f9db82;
  border: none;
  margin-top: 16px;
  cursor: pointer;
}
.CountWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.CountBox {
  flex: 1;
  height: 94px;
  border-radius: 20px;
  background-color: #000000;
  margin-right: 30px;
  margin-bottom: 15px;
  border-radius: 20px;
  background-color: #000000;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Count {
  /* Style for "72500" */
  width: 74px;
  height: 43px;
  color: #f1b62a;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  /* Text style for "72500" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.socialContainer{
  display: flex;
  flex-direction: row;
  
}
.CountTitle {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  /* Text style for "Gün" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #ffffff;
}
.SocialMedia {
  display: flex;
  flex-direction: row;
  height: 108px;
  padding: 8px 5%;
  background-color: #f1b62a;
  width: 90%;
  padding-bottom: 50px;
  justify-content: space-between;
  align-items: center;
}

.linkContainer {
  display: flex;
  flex-direction: column;
}

.SocialMediaIcon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-right: 45px;
  display: flex;
}
.SocialMediaIcon:hover {
  opacity: 0.7;
}

.AddIcon {
  display: flex;
  position: relative;
  width: 48px;
  margin-left: 10px;
  height: 48px;
}

.contactTitle {
  /* Style for "GEL AL | S" */
  color: #000000;
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin-block-start: 0em;
  margin-block-end: 0em;

  /* Text style for "GEL" */

  /* Text style for "AL |" */
}

.contactIcon {
  margin-right: 20px;
}
.contactYellow {
  color: #f1b62a;
  font-family: "Roboto Bold" !important;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.contactBlack {
  font-family: "Roboto Bold" !important;
  font-size: 36px;
  font-weight: 700;
  color: #000000;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  /* Text style for "Sen gitme" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.contactSection {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.sectionWrapper {
  width: 100%;
  display: flex;
}
.section {
  width: 100%;
  display: flex;
}
.section1 {
  width: 100%;
  margin-left: 12px;
  display: flex;
}
.whiteText{
  color:white
}

@media (max-width: 400px) {
  .left {
    width: 100%;
    margin-left: 0;
  }
  
  .kvkk {
    margin: 8px 0px;
  }
  .ButtonText {
    display: flex;
    font-family: "Roboto";
    color: #000000;
    font-size: 18px !important;
    font-weight: 400;
    font-style: normal;
    z-index: 14;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin-left: 80px;
  }
}

@media (max-width: 1000px) {
  .left {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  .left {
    width: 100%;
    margin-left: 0;
  }
  .linkContainer{
    margin-top: 16px;
  }
  .contactSection {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .socialContainer{
    margin-bottom: 16px;
  }
  .FooterForm {
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-right: 0;
  }
  .Footer {
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 auto;
    height: auto;
  }
  .Form {
    display: grid;
    margin-bottom: 50px;
    width: 90vw;
  }
  .FooterContent{
    margin-top: 32px;
  }
  .FooterText{
    width: 90% !important;
    margin-top: 16px;
  }
  .FooterContainer {
    width: 100%;
    z-index: 6;
    margin: 24px auto;
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .SocialMedia{
    flex-direction: column-reverse;
    justify-content: center;
  padding: 80px 11%;

  }
}
