.Row {
  display: flex;
  flex-direction: row;
  width: 100%;
  div {
    width: 100%;
    padding: 10px;
    flex: 1;
    border: 1px solid #1d1d1d;
  }
}

.RowColored {
  display: flex;
  flex-direction: row;
  width: 100%;
  div {
    width: 100%;
    background: #f1b62a;
    padding: 10px;
    flex: 1;
    border: 1px solid #1d1d1d;
  }
}

.RowFirst {
  display: flex;
  flex-direction: row;
  width: 100%;
  div:first-child {
    background: #f1b62a;
    width: 100%;
    flex: 1;
    border: 1px solid #1d1d1d;
  }
  div {
    width: 100%;
    flex: 3;
    border: 1px solid #1d1d1d;
  }
}
