.DownloadIconsArea {
  display: flex;
  margin-top: 16px;
  margin-bottom: 24px;
  z-index: 13;
  text-align: center;
  flex-direction: row;
  width: 100%;
}
.DownloadIcons {
  margin-left: 10%;
  cursor: pointer;
}
.BannerContainer {
  height: 100%;
  width: 100%;
}

.BannerContent {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: 0 auto;
  font-family: Roboto;
  margin-top: 108px;
  font-size: 20px;
  justify-content: space-between;
  font-weight: 400;
  text-align: left;
}
.CountDownWrapper {
  display: flex;
  flex: 0.8;
  z-index: 99;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.ImageWrapper {
  display: flex;
  justify-content: flex-end;
  flex: 2;
  padding-right: 4%;
}
.BannerImage {
  height: 86vh;
  min-height: 400px;
  z-index: 20;
}
.BannerTitleWrapper {
  padding-left: 10%;
  margin-top: 32px;
}
.BannerTitle {
  color: #f1b62a;
  font-family: "Roboto Bold";
  font-size: 75px;
  font-weight: 700;
  line-height: 75px;
}
.BannerTitleWhite {
  color: #000000 !important;
  font-family: "Roboto Bold";
  font-size: 75px;
  line-height: 75px;
  font-weight: 700;
}
.BannerTitleRegular {
  color: #000000;
  font-family: "Roboto";
  line-height: 75px;
  font-size: 60px;
  font-weight: 700;
  white-space: nowrap;
}
.BannerText {
  font-family: "Roboto";
  margin-left: 10%;
  margin-right: -32%;
  font-size: 16px;
  width: 90%;
}
.BannerCountDown {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
.CountBox {
  flex: 1;
  margin-right: 15px;
  width: 100%;
  height: 94px;
  border-radius: 20px;
  background-color: #000000;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 14;
  align-items: center;
}

.Count {
  /* Style for "72500" */
  width: 74px;
  height: 43px;
  color: #f1b62a;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  z-index: 14;
  line-height: normal;
  text-align: center;
  /* Text style for "72500" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.CountTitle {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  /* Text style for "Gün" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  z-index: 14;
  color: #ffffff;
}
.BannerButtonWrapper {
  width: 76%;
  min-width: 300px;
  height: 56px;
  cursor: pointer;
  margin-left: 10%;
  border-radius: 20px;
  background-color: #f1b62a;
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
  position: relative;
  z-index: 13;
  align-items: center;
}
.BannerButtonWrapper:hover {
  opacity: 0.8;
}
.AddIcon {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}

.ButtonText {
  display: flex;
  font-family: "Roboto";
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}
.CountBoxWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  z-index: 14;
}

.textWrapper {
  display: flex;
  flex-direction: row;
}

@media (max-width: 400px) {
  .ButtonText {
    display: flex;
    font-family: "Roboto";
    color: #000000;
    font-size: 18px !important;
    font-weight: 400;
    font-style: normal;
    z-index: 14;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin-left: 15px;
  }
  .DownloadIconsArea {
    display: flex;
    margin-top: 30px;
    text-align: center;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .textWrapper {
    flex-direction: column;
  }
  .BannerTitleRegular {
    white-space: normal;
  }
}

@media (max-width: 1024px) {
  .ImageWrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex: 1;
  }
  .BannerImage {
    display: none;
  }
  .CountDownWrapper {
    display: flex;
    flex: 0.8;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 0px;
  }
  .BannerBackground {
    height: 500px;
    position: absolute;
    width: 100%;
    background-size: cover;
    background-position: top;
    z-index: -1;
    top: -100px;
    right: 0px;
  }
  .BannerContainer {
    height: 100%;
    width: 100%;
    z-index: 6;
    margin-top: 100px;
  }
  .BannerCountDown {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .BannerContent {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
  .BannerText {
    width: 80%;
  }
}
