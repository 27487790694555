.rowStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -10px;
}
.titleStyle {
  width: 160px;
  font-weight: 600;
}

.descStyle {
  width: calc(100% - 160px);
}

li.item { 
  padding: 10px; 
  font-weight: 600;
  counter-increment: ordem; 
}
.foo { 
  font-weight: 400;
  counter-reset: foo; 
  list-style-type: decimal; 
  padding-left: 15px; 
}
.foo li { 
  list-style-type: none; 
  list-style: none;
  margin-bottom: 8px;
}


.RowColored {
  display: flex;
  flex-direction: row;
  width: 100%;
  div {
    width: 100%;
    background: #f1b62a;
    padding: 10px;
    flex: 1;
    border: 1px solid #1d1d1d;
  }
}

.RowFirst {
  display: flex;
  flex-direction: row;
  width: 100%;
  div:first-child {
    background: #f1b62a;
    width: 100%;
    flex: 1;
    border: 1px solid #1d1d1d;
  }
  div {
    width: 100%;
    flex: 3;
    border: 1px solid #1d1d1d;
  }
}
