.Visual {
  width: 100%;
  margin-top: 108px;
}
.VisualContainer {
  width: 90%;
  z-index: 6;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row-reverse;
}

.VisualImage {
  margin-top: 61px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex: 1;
}

.VisualContent {
  display: flex;
  flex-direction: column;
  flex: 2;
}
.VisualTitle {
  text-align: start;
  height: auto;
  color: #000000;
  font-size: 48px;
  font-family: "Roboto Bold";
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  /* Text style for "Go" */
  font-style: normal;
  margin-bottom: 10px;
}
.VisualText {
  text-align: start;
  color: #313131;
  font-family: "Roboto Bold";
  font-size: 16px;
  margin-top: 24px;
  width: 80%;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
.VisualButton {
  width: 306px;
  height: 64px;
  border-radius: 100px;
  background-color: #f1b62a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BannerImage {
  width: 55%;
}

@media (max-width: 1024px) {
  .VisualContainer {
    width: 90%;
    z-index: 6;
    margin: 0 auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .BannerImage {
    width: 80%;
    margin-top: 24px;
  }
  .VisualTitle {
    text-align: start;
    height: auto;
    font-family: "Roboto Bold";
    color: #000000;
    font-size: 24px;
    margin-top: 24px;
  }
  .VisualContent {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .VisualBackground {
    position: relative;
    width: 100%;
    height: 50px;
    background-position: top;
    background-size: cover;
  }
  .VisualText {
    margin-top: 0px;
    margin-bottom: 36px;
    font-family: "Roboto";
    width: 90%;
  }
}
