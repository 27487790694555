.InputContainer {
  position: relative;
  width: 99%;
  margin-bottom: 12px;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@include placeholder {
  color: #7e7e7e;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  /* Text style for "İsim" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.Label {
  top: 8px;
  left: 36px;
  position: absolute;
  height: 20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: "#d6d5d5";
}
.Input {
  width: 100%;
  height: 42px;
  font-family: Roboto;
  font-size:18px;
  display: flex;
  flex: 1;
  border-radius: 10px;
  padding-left: 22px;
  background-color: #f5f5f5;
  border: none;
  margin-right: 12px;
  margin-bottom: 12px;
  &:focus {
    border: none;
  }
}
.Required {
  top: 6px;
  right: 23px;
  position: absolute;
  width: 47px;
  height: 20px;
  color: #f1b62a;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
  /* Text style for "*zorunlu" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
