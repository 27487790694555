.WhatIsContainer {
  width: 100%;
  height: calc(100vh - 108px);
  display: flex;
  overflow: hidden;
  background-color: white;
  flex-direction: row;
}

.CountDownWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: left;
}
.WhatIsTitleWrapper {
  padding-left: 10%;
  padding-top: 32px;
  height: 100%;
}
.packageImage {
  height: 60%;
  padding-left: 10%;
}
.WhatIsTitle {
  color: #f1b62a;
  font-family: "Roboto Bold";
  font-size: 75px;
  font-weight: 700;
  line-height: 75px;
}
.WhatIsTitleWhite {
  color: #000000 !important;
  font-family: "Roboto Bold";
  font-size: 75px;
  line-height: 75px;
  font-weight: 700;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  width: 35%;
  justify-content: center;
  margin: 8px;
}
.rowContainer {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}
.infoIcon {
  width: 110px;
  height: 100px;
  margin-right: 16px;
}
.textContainer {
  display: flex;
  flex-direction: column;
}
.infoTitle {
  font-size: 16px;
  font-family: "Roboto Bold";
  margin-bottom: 16px;
}
.infoDesc {
  font-size: 14px;
  font-family: "Roboto";
}

@media (max-width: 1024px) {
  .WhatIsContainer {
    flex-direction: column;
  }
  .packageImage {
    display: none;
  }
  .rowContainer {
    flex-direction: column;
    margin-top: 0px;
  }
  .infoContainer {
    width: 90%;
    margin-top: 40px;
  }
  .ImageWrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex: 1;
  }
  .WhatIsImage {
    width: 100%;
    height: 100%;
  }
  .CountDownWrapper {
    display: flex;
    flex: 0.8;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 0px;
  }
  .WhatIsBackground {
    height: 500px;
    position: absolute;
    width: 100%;
    background-size: cover;
    background-position: top;
    z-index: -1;
    top: -100px;
    right: 0px;
  }
  .WhatIsContainer {
    height: 100%;
    width: 100%;
    z-index: 6;
    margin-top: 100px;
  }
  .WhatIsContent {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
}
