

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Bold";
  src: url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}

h1 {
  font-family: "Roboto Bold";
}
h3 {
  font-family: "Roboto Bold";

}
p {
  font-family: "Roboto";
}

.mainTitleBlack {
  line-height: 0px;
  color: #000000;
  font-family: "Roboto";
}
.mainTitleYellow {
  line-height: 0px;
  color: #f1b62a;
  font-family: "Roboto";
}
